import {mapActions, mapGetters, mapMutations} from "vuex";
//sections
import orderAccordion from '../../components/order-accordion/index.vue';

export default {
    name: "orders",
    components: {
        orderAccordion
    },
    data() {
        return {}
    },

    created() {
        this.fetchCurrentOrders()
    },
    computed: {
        ...mapGetters({
            profileRequestsFlag: 'profile/isLoadingProfile',
            currentOrders: 'profile/currentOrders'
        })
    },

    methods: {

        ...mapMutations({}),
        ...mapActions({
            fetchCurrentOrders: 'profile/GET_CURRENT_ORDERS'
        })
    }
}
